<template>
  <div class="temp-deil">
    <Heard />
    <div class="headtop container">
      <img :src="deilobj.viewImg" alt="" />
      <div class="rg">
        <p class="tit">{{ deilobj.title }}</p>
        <p class="desc">{{ deilobj.remark }}</p>
        <!-- <p class="pr">费用 <span>￥22800.00</span>{{ deilobj.title }}</p> -->
        <div class="btn" @click="gopay">立即报名</div>
      </div>
    </div>
    <div class="courcont container">
      <p class="coursedesc">课程概述</p>
      <div class="line"></div>
      <img :src="deilobj.image" alt="" />

      <a
        class="posbox"
        :style="{ bottom: pohei + 'px' }"
        :href="oturl"
        target="_black"
      ></a>
    </div>

    <Footer />

    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="mantleinfo">
        <p class="title">报名成功</p>
        <img src="@/assets/image/succsav.png" alt="" />
        <!-- <p class="descinfo">
          专业的师资团队给你系统的课程讲授和完整的贴心服务，只为你能轻松就业
        </p>
        <el-form
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-width="100px"
        >
          <el-form-item prop="name" label="你的称呼">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone" label="你的手机">
            <el-input
              v-model="ruleForm.phone"
              placeholder="用于联系您"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="你的专业" prop="course">
            <el-input
              v-model="ruleForm.course"
              placeholder="分析你适合的课程"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" size="mini" @click="submitForm('ruleForm')"
          >提 交</el-button
        > -->
        <el-button type="primary" size="mini" @click="dialogVisible = false"
          >完 成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUser } from "@/plugins/auth.js";
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import {
  api_saveHrOrder,
  api_queryHrOrder,
  api_v1queryProduct,
} from "@/apis/apis.js";
export default {
  components: {
    Heard,
    Footer,
  },
  data() {
    return {
      mantle: false,
      id: "",
      activityName: "",
      activityPhone: "",
      deilobj: {},

      newdeilobj: {},
      userId: "",
      paydeil: {},
      dialogVisible: false,
      list: [],
      newslist: [],
      ruleForm: {
        name: "",
        phone: "",
        course: "",
      },
      rules: {
        name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入正确的手机号码", trigger: "blur" },
        ],
        course: [
          { required: true, message: "请输入专业昵称", trigger: "blur" },
        ],
      },
      pohei: 1,
      oturl: "",
    };
  },

  async created() {
    this.userId = await getUser().id;
    this.activityName = await getUser().nickname;
    this.activityPhone = await getUser().mobile;
    this.id = await this.$route.params.id;

    await this.getdeil();
    if (this.$route.params.id == 31) {
      this.pohei = 2100;
      this.oturl =
        "https://img.hrclass.com.cn/job/job/IC%E9%AA%8C%E8%AF%81%E8%AF%BE%E7%A8%8B%E8%A1%A8.png";
      return;
    } else if (this.$route.params.id == 32) {
      this.pohei = 2140;
      this.oturl =
        "https://img.hrclass.com.cn/job/job/%E5%90%8E%E7%AB%AF%E8%AF%BE%E7%A8%8B%E8%A1%A8.png";

      return;
    } else if (this.$route.params.id == 33) {
      this.pohei = 2120;
      this.oturl =
        "https://img.hrclass.com.cn/job/job/%E5%89%8D%E7%AB%AF%E8%AF%BE%E7%A8%8B%E8%A1%A8.png";
      return;
    } else if (this.$route.params.id == 34) {
      this.pohei = 2120;
      this.oturl =
        "https://img.hrclass.com.cn/job/job/%E7%89%88%E5%9B%BE%E8%AE%BE%E8%AE%A1%E8%AF%BE%E7%A8%8B%E8%A1%A8.png";
      return;
    }
  },
  methods: {
    getdeil() {
      api_v1queryProduct({
        id: this.id,
      }).then((res) => {
        this.deilobj = res.data.data;
      });
    },

    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },

    gopay() {
      console.log(this.userId, "userId");
      if (!this.userId) {
        this.$message({
          message: "您还未登录,请先登录",
          type: "warning",
        });
        return;
      }
      api_saveHrOrder({
        sourceId: this.id,
        sourceType: "cJob",
        totalPrice: 0,
        payPrice: 0,
        name: this.deilobj.title,
        image: this.deilobj.viewImg, //产品图片
        payType: 5,
        userId: this.userId,
        downloadNum: 0,
        activityName: this.activityName,
        activityPhone: this.activityPhone,
      }).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.dialogVisible = true;
        }
      });
    },
    payprice() {
      this.actindex = 1;
    },
    paynum() {
      this.actindex = 2;
    },
    ispaysuccess(res) {
      var time = setInterval(() => {
        api_queryHrOrder({
          id: res.id,
          userId: res.userId,
        }).then((res) => {
          console.log(res.data.data);
          console.log(res.data.data.status, "zhuangtai");
          if (res.data.data.status == 1) {
            clearInterval(time);
            setTimeout(() => {
              this.$message({
                message: "支付成功---",
                type: "success",
              });
              this.mantle = false;
              this.$router.go(0);
            }, 2000);
          }
        });
      }, 2000);
    },
  },
  watch: {
    //监听当前路由的变化
    $route(newval) {
      //newval：变化后的新数据
      //oldval：变化前的数据
      //   console.log(oldval.params.id, "23131");
      this.id = newval.params.id;
      this.getdeil();
    },
  },
};
</script>

<style lang="less" scoped>
.posbox {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  left: 750px;
}
.container-fluid {
  min-width: 1200px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}
.temp-deil {
  .headtop {
    height: 280px;
    border: 1px dashed #ccc;
    margin-bottom: 20px;
    display: flex;
    padding: 32px 35px;
    img {
      width: 360px;
      height: 210px;
      margin-right: 38px;
    }
    .rg {
      .tit {
        font-size: 28px;
        color: #000000;
      }
      .desc {
        font-size: 18px;
        color: #969696;
        margin-top: 12px;
        margin-bottom: 28px;
      }
      .pr {
        font-size: 18px;
        span {
          font-size: 30px;
          font-weight: bold;
          margin-left: 10px;
          color: #ff632a;
        }
      }
      .btn {
        width: 150px;
        height: 44px;
        background: #2a84e0;
        border-radius: 4px 4px 4px 4px;
        font-size: 20px;
        font-weight: 500;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        margin-top: 46px;
      }
    }
  }
  .courcont {
    border: 1px dashed #ccc;
    padding: 31px 34px;
    position: relative;
    .coursedesc {
      font-size: 28px;
      color: #000000;
    }
    .line {
      width: 121px;
      height: 16px;
      background: #2a84e0;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.4;
      margin-top: -10px;
    }
  }
}

.mantleinfo {
  text-align: center;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
  }
  img {
    width: 295px;
    height: 198px;
    margin-top: 33px;
    margin-bottom: 100px;
  }
  .descinfo {
    margin: 30px;
    margin-bottom: 50px;
  }
}
</style>